<div *ngFor="let notification of notifications$ | async" id="notification-container">
	<div style="margin-left: 20px; margin-top: 20px; height: 100%" *transloco="let t">
		<p id="subject" class="item-style">
			{{ notification.subject }}
		</p>
		<div id="from-label" class="pop-up-items">{{ t('cloud.shared.notification.from') }}</div>
		<p id="sender-email" class="item-style">{{ notification.sender.email }}</p>
		<div id="received-label" class="pop-up-items">{{ t('cloud.shared.notification.received') }}</div>
		<p id="sent-at" class="item-style">{{ notification.sentAt | date: 'hh:mm a  MMM-d-yyyy' }}</p>
		<div id="message-label" class="pop-up-items">{{ t('cloud.shared.notification.message') }}</div>
		<div *ngIf="notification.body" [innerHTML]="notification.body"></div>
	</div>
	<div style="padding-bottom: 10px; overflow: hidden" *transloco="let t">
		<span id="close-popup-button" (click)="closePopup()" class="close-button highlight-hover">{{
			t('cloud.shared.notification.close')
		}}</span>
		<span
			*ngIf="isListPage"
			id="archive-button"
			(click)="markAsArchive(notification.id)"
			class="delete-buttons highlight-hover"
			>{{ t('cloud.shared.notification.archive') }}</span
		>
		<span
			*ngIf="!isListPage"
			id="clear-button"
			class="delete-buttons highlight-hover"
			(click)="markAsRead(notification.id)"
			>{{ t('cloud.shared.notification.clear') }}</span
		>
		<span id="go-to-app-button" class="close-button highlight-hover" (click)="closePopup()">{{
			t('cloud.shared.notification.go-to-app')
		}}</span>
	</div>
</div>
