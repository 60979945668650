/* libs/cloud/shared/notifications-bell-icon/src/lib/notifications-bell-icon-main.component.scss */
:host #sh-header-toolbar-notifications-button-div {
  margin-right: 30px;
  width: 33px;
  height: 33px;
}
:host #sh-header-toolbar-notifications-button-div #sh-header-toolbar-notifications-button {
  padding: 8px 16px;
}
:host .notification-icon-style {
  color: var(--prism-color-theme-ink) !important;
}
/*# sourceMappingURL=notifications-bell-icon-main.component.css.map */
