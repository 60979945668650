/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PostMailRequest } from '../models/post-mail-request';
import { PostNotificationResponse } from '../models/post-notification-response';


/**
 * This lists and explains endpoints that relate to email notifications.
 */
@Injectable({
  providedIn: 'root',
})
export class MailEndpointApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postAttachment
   */
  static readonly PostAttachmentPath = '/attachment';

  /**
   * create an attachment to send it over email.
   *
   * create an attachment to send it over email
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAttachment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAttachment$Response(params: {
    context?: HttpContext
    body: {
'filename'?: string;
}
  }
): Observable<StrictHttpResponse<{
'filename'?: string;
'id'?: string;
'uploadUrl'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, MailEndpointApiService.PostAttachmentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'filename'?: string;
        'id'?: string;
        'uploadUrl'?: string;
        }>;
      })
    );
  }

  /**
   * create an attachment to send it over email.
   *
   * create an attachment to send it over email
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAttachment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAttachment(params: {
    context?: HttpContext
    body: {
'filename'?: string;
}
  }
): Observable<{
'filename'?: string;
'id'?: string;
'uploadUrl'?: string;
}> {

    return this.postAttachment$Response(params).pipe(
      map((r: StrictHttpResponse<{
'filename'?: string;
'id'?: string;
'uploadUrl'?: string;
}>) => r.body as {
'filename'?: string;
'id'?: string;
'uploadUrl'?: string;
})
    );
  }

  /**
   * Path part for operation postMail
   */
  static readonly PostMailPath = '/mail';

  /**
   * Send an email.
   *
   * The endpoint to issue a notification email. When an email is sent, there is a pre-configured email sender account which is used to send out the email.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postMail$Response(params: {

    context?: HttpContext

    /**
     * The request payload to issue a notification email. When an email is sent, there is a pre-configured email sender account which is used to send out the email.
     */
    body?: PostMailRequest
  }
): Observable<StrictHttpResponse<PostNotificationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MailEndpointApiService.PostMailPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostNotificationResponse>;
      })
    );
  }

  /**
   * Send an email.
   *
   * The endpoint to issue a notification email. When an email is sent, there is a pre-configured email sender account which is used to send out the email.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postMail(params: {

    context?: HttpContext

    /**
     * The request payload to issue a notification email. When an email is sent, there is a pre-configured email sender account which is used to send out the email.
     */
    body?: PostMailRequest
  }
): Observable<PostNotificationResponse> {

    return this.postMail$Response(params).pipe(
      map((r: StrictHttpResponse<PostNotificationResponse>) => r.body as PostNotificationResponse)
    );
  }

}
