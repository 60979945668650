/* libs/cloud/shared/notifications-bell-icon/src/lib/ui/components/notifications-popup/notifications-popup.component.scss */
::ng-deep .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: var(--mdc-dialog-container-shape, 4px);
  background-color: var(--prism-color-theme-neutral-pop);
  padding: 5px;
}
.list-style {
  margin-top: 2px;
  padding: 12%;
}
.header-style {
  color: var(--prism-color-theme-ink);
  font-weight: bold;
  font-size: 15px;
}
.content {
  display: flex;
  flex-direction: column;
  top: 5px;
  margin: 0%;
  border: transparent;
}
mat-divider {
  border-top: 1px solid var(--prism-color-theme-neutral-dim);
  margin: 8px 0;
}
.notification-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--prism-color-theme-brand-red);
  margin-right: 12px;
  margin-bottom: 8px;
  margin-top: 5px;
}
.close-button {
  color: var(--prism-color-theme-accent-primary) !important;
  font-weight: bold;
  font-size: small;
  cursor: pointer;
  margin-top: 5px;
  padding: 35px;
}
.clear-buttons {
  margin-left: 45%;
  color: var(--prism-color-theme-accent-primary) !important;
  font-weight: bold;
  font-size: small;
  cursor: pointer;
  margin-top: 5px;
}
.message-style {
  display: flex;
  align-items: center;
}
.custom-message {
  color: var(--prism-color-theme-ink);
  margin-bottom: 5px;
  font-size: small;
  flex-grow: 1;
  padding: 2px;
}
.custom-date {
  color: var(--prism-color-theme-ink);
  font-size: small;
  padding: 2px;
}
.custom-button {
  font-weight: bolder;
  align-items: center;
  color: var(--prism-color-theme-brand-blue);
  font-size: small;
  cursor: pointer;
}
.mat-dialog-container {
  background-color: var(--prism-color-theme-neutral-pop) !important;
}
.clear-button {
  margin-left: 15px;
  color: var(--prism-color-theme-neutral-pop-higher);
  border: 0px var(--prism-color-theme-neutral-pop);
  background: var(--prism-color-theme-neutral-pop-high);
  border-radius: 14px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: x-small;
  height: 25px;
  width: 75px;
  transition:
    opacity 0.1s ease,
    color 0.1s ease,
    border-color 0.1s ease;
}
.details-button {
  margin-left: 15px;
  color: var(--prism-color-theme-spacer);
  border: 0px var(--prism-color-theme-neutral-pop);
  background: var(--prism-color-theme-accent-primary);
  border-radius: 14px;
  padding: 5px 15px;
  font-size: small;
}
.clear-button-style {
  margin-left: 15px;
  color: var(--prism-color-theme-accent-primary);
  border: 0px var(--prism-color-theme-neutral-pop);
  font-size: small;
  cursor: pointer;
}
.clear-button:hover {
  border: 0px solid;
}
.button-style {
  margin: 15px;
}
.current-date-style {
  color: var(--prism-color-theme-ink);
  margin-top: 20px;
  margin-left: 15px;
}
.loading-style {
  color: var(--prism-color-theme-ink);
  padding: 10%;
  margin-left: 20px;
}
.no-notification {
  color: var(--prism-color-theme-ink);
  padding: 10%;
  margin-left: 20px;
}
.notification-list-container {
  max-height: 300px;
  overflow-y: auto;
}
.button-group {
  margin-bottom: 5%;
  overflow-y: hidden;
  margin-top: 5%;
}
.highlight-hover:hover {
  background-color: var(--prism-color-theme-accent-primary);
  padding: 5px 6px;
  border-radius: 30px;
}
/*# sourceMappingURL=notifications-popup.component.css.map */
