/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetTemplatesResponse } from '../models/get-templates-response';
import { PatchTemplateRequest } from '../models/patch-template-request';
import { PostTemplateRequest } from '../models/post-template-request';
import { PostTemplateResponse } from '../models/post-template-response';


/**
 * This lists and explains endpoints that relate to templates.
 */
@Injectable({
  providedIn: 'root',
})
export class TemplatesEndpointApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getTemplates
   */
  static readonly GetTemplatesPath = '/{applicationId}/templates';

  /**
   * List all templates.
   *
   * Get a list of templates
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTemplates$Response(params: {

    /**
     * The applicationId this template is associated to
     */
    applicationId: string;

    /**
     * The maximum number of items retrieved is &lt;&#x3D; &#x60;limit&#x60;.
     */
    limit?: string;

    /**
     * Offset
     */
    offset?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<{

/**
 * The total count of templates available.
 */
'itemsCount'?: number;

/**
 * An array of templates, for the given limit and offsets.
 */
'items'?: Array<GetTemplatesResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesEndpointApiService.GetTemplatesPath, 'get');
    if (params) {
      rb.path('applicationId', params.applicationId, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * The total count of templates available.
         */
        'itemsCount'?: number;
        
        /**
         * An array of templates, for the given limit and offsets.
         */
        'items'?: Array<GetTemplatesResponse>;
        }>;
      })
    );
  }

  /**
   * List all templates.
   *
   * Get a list of templates
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTemplates(params: {

    /**
     * The applicationId this template is associated to
     */
    applicationId: string;

    /**
     * The maximum number of items retrieved is &lt;&#x3D; &#x60;limit&#x60;.
     */
    limit?: string;

    /**
     * Offset
     */
    offset?: string;
    context?: HttpContext
  }
): Observable<{

/**
 * The total count of templates available.
 */
'itemsCount'?: number;

/**
 * An array of templates, for the given limit and offsets.
 */
'items'?: Array<GetTemplatesResponse>;
}> {

    return this.getTemplates$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * The total count of templates available.
 */
'itemsCount'?: number;

/**
 * An array of templates, for the given limit and offsets.
 */
'items'?: Array<GetTemplatesResponse>;
}>) => r.body as {

/**
 * The total count of templates available.
 */
'itemsCount'?: number;

/**
 * An array of templates, for the given limit and offsets.
 */
'items'?: Array<GetTemplatesResponse>;
})
    );
  }

  /**
   * Path part for operation postTemplate
   */
  static readonly PostTemplatePath = '/{applicationId}/template';

  /**
   * Create a new notification template.
   *
   * Create a new notification template
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postTemplate$Response(params: {

    /**
     * The applicationId this template is associated to
     */
    applicationId: string;
    context?: HttpContext
    body?: PostTemplateRequest
  }
): Observable<StrictHttpResponse<PostTemplateResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesEndpointApiService.PostTemplatePath, 'post');
    if (params) {
      rb.path('applicationId', params.applicationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostTemplateResponse>;
      })
    );
  }

  /**
   * Create a new notification template.
   *
   * Create a new notification template
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postTemplate(params: {

    /**
     * The applicationId this template is associated to
     */
    applicationId: string;
    context?: HttpContext
    body?: PostTemplateRequest
  }
): Observable<PostTemplateResponse> {

    return this.postTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<PostTemplateResponse>) => r.body as PostTemplateResponse)
    );
  }

  /**
   * Path part for operation deleteTemplate
   */
  static readonly DeleteTemplatePath = '/{applicationId}/template/{id}';

  /**
   * Delete a notification template.
   *
   * Delete notification template
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTemplate$Response(params: {

    /**
     * The applicationId this template is associated to
     */
    applicationId: string;

    /**
     * The resource unique identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesEndpointApiService.DeleteTemplatePath, 'delete');
    if (params) {
      rb.path('applicationId', params.applicationId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete a notification template.
   *
   * Delete notification template
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTemplate(params: {

    /**
     * The applicationId this template is associated to
     */
    applicationId: string;

    /**
     * The resource unique identifier
     */
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchTemplate
   */
  static readonly PatchTemplatePath = '/{applicationId}/template/{id}';

  /**
   * Patch a notification template.
   *
   * Patch a notification template
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTemplate$Response(params: {

    /**
     * The applicationId this template is associated to
     */
    applicationId: string;

    /**
     * The resource unique identifier
     */
    id: string;
    context?: HttpContext
    body?: PatchTemplateRequest
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatesEndpointApiService.PatchTemplatePath, 'patch');
    if (params) {
      rb.path('applicationId', params.applicationId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Patch a notification template.
   *
   * Patch a notification template
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTemplate(params: {

    /**
     * The applicationId this template is associated to
     */
    applicationId: string;

    /**
     * The resource unique identifier
     */
    id: string;
    context?: HttpContext
    body?: PatchTemplateRequest
  }
): Observable<void> {

    return this.patchTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
