/* libs/cloud/shared/notifications-bell-icon/src/lib/ui/components/notification-details/notification-details.component.scss */
.pop-up-items {
  color: var(--prism-color-theme-neutral-pop-highest);
  font-size: small;
}
.item-style {
  color: var(--prism-color-theme-ink);
}
.delete-buttons {
  margin-left: 55%;
  color: var(--prism-color-theme-accent-primary) !important;
  margin-right: 10px;
  font-size: small;
  cursor: pointer;
  margin-top: 5px;
}
.close-button {
  margin-left: 15px;
  color: var(--prism-color-theme-accent-primary);
  border: 0px var(--prism-color-theme-neutral-pop);
  font-size: small;
}
.highlight-hover:hover {
  background-color: var(--prism-color-theme-neutral-pop);
  padding: 5px 6px;
  border-radius: 30px;
}
#subject {
  margin-top: 10px;
}
/*# sourceMappingURL=notification-details.component.css.map */
